<template>
  <iframe
    id="livePreview"
    ref="livePreviewIframe"
    :style="{
      width: $store.getters['themeEditor/iframeWidth']
    }"
    :src="$store.getters['themeEditor/iframeUrl']"
  />
</template>

<script>
import {
  onBeforeMount, onMounted, onUnmounted, ref,
} from 'vue'
import store from '@/store'
import themeBlockItemStoreModule from '@/views/models/settings/layout-settings/themeBlockItemStoreModule'
import { isDevelopment } from '@core/utils/utils'

export default {
  components: {
  },
  emits: ['update:settings'],
  props: {
    settings: {
      type: Object,
      default: () => ({
        test: [],
      }),
    },
  },
  data() {
    return {
      // settings: null,
    }
  },
  beforeCreate() {

  },
  methods: {
    handleTabChangedEvent() {
      // this.$nextTick(() => {
      //   // TODO: Subpage breadcrumb
      //   const selectedTabIndex = this.$refs.tabs.currentTab
      //   document.getElementById('appBreadcrumb')
      //     .getElementsByClassName('active')[0]
      //     .textContent = this.$refs.tabs.$refs.nav.children[selectedTabIndex].textContent
      // })
    },
  },
  setup(props, ctx) {
    const THEME_EDITOR_STORE_MODULE_NAME = 'themeEditor'
    const SECTION_BLOCK_STORE_MODULE_NAME = 'theme_section_blocks'

    // Register module
    // if (!store.hasModule(THEME_EDITOR_STORE_MODULE_NAME)) store.registerModule(THEME_EDITOR_STORE_MODULE_NAME, themeEditorStoreModule)
    if (!store.hasModule(SECTION_BLOCK_STORE_MODULE_NAME)) store.registerModule(SECTION_BLOCK_STORE_MODULE_NAME, themeBlockItemStoreModule)
    onMounted(() => {
      // if (!store.hasModule(THEME_EDITOR_STORE_MODULE_NAME)) store.registerModule(THEME_EDITOR_STORE_MODULE_NAME, themeEditorStoreModule)
      if (!store.hasModule(SECTION_BLOCK_STORE_MODULE_NAME)) store.registerModule(SECTION_BLOCK_STORE_MODULE_NAME, themeBlockItemStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(THEME_EDITOR_STORE_MODULE_NAME)) store.unregisterModule(THEME_EDITOR_STORE_MODULE_NAME)
      if (store.hasModule(SECTION_BLOCK_STORE_MODULE_NAME)) store.unregisterModule(SECTION_BLOCK_STORE_MODULE_NAME)
    })

    const fetchSettings = () => {
      // isLoading.value = true
    }
    onBeforeMount(() => {
      fetchSettings()
    })

    const onSectionUpdated = (groupKey, sectionId, sectionData) => {
      const localSettings = JSON.parse(JSON.stringify(props.settings))
      const groupSections = localSettings[groupKey]
      groupSections.forEach((section, index) => {
        if (section.id === sectionId) {
          localSettings[groupKey][index] = sectionData
        }
      })

      ctx.emit('update:settings', localSettings)
    }

    const onSubmitSection = (groupKey, sectionId, newSettings, callback) => {
      store.dispatch(`${THEME_EDITOR_STORE_MODULE_NAME}/update`, { id: sectionId, settings: newSettings })
        .then(response => {
          onSectionUpdated(groupKey, sectionId, response)
          callback(true, response)
        })
        .catch(() => {
          callback(false)
        }).finally(() => {
          callback(null)
        })
    }

    const onBlockSorting = (sectionId, blocksSorting) => {
      store.dispatch(`${SECTION_BLOCK_STORE_MODULE_NAME}/sorting`, { section_id: sectionId, items: blocksSorting.map((itemId, displayOrder) => ({ id: itemId, display_order: displayOrder + 1 })) })
        .then(() => {
        })
        .catch(() => {
        }).finally(() => {
        })
    }

    const onBlockDeleted = (groupKey, sectionId, blockTemplateId, blockId) => {
      const localSettings = JSON.parse(JSON.stringify(props.settings))
      const groupSections = localSettings[groupKey]
      groupSections.forEach((section, sectionIndex) => {
        if (section.id === sectionId) {
          section.blocks.forEach((blockTemplate, blockTemplateIndex) => {
            if (blockTemplate.id === blockTemplateId) {
              blockTemplate.items.forEach((blockItem, blockItemIndex) => {
                if (blockItem.block_id === blockId) {
                  localSettings[groupKey][sectionIndex].blocks[blockTemplateIndex].items.splice(blockItemIndex, 1)
                  ctx.emit('update:settings', localSettings)
                }
              })
            }
          })
        }
      })
    }

    const processInspectorMessage = event => {
      // We can check the origin of event `event.origin`
      if ((typeof (event.data) !== 'object') || !Object.hasOwn(event.data, 'event')) {
        return
      }

      if (event.source === window) {
        return
      }

      switch (event.data?.event || null) {
        case 'sectionHover':
          // store.commit('themeEditor/UPDATE_HOVERED_SECTION_ID', event.data.value || null)
          break
        case 'sectionSelection':
          store.dispatch('themeEditor/loadSection', event.data.value)
          break
        default:
          if (isDevelopment()) {
            console.log('Unknown message', event.data)
          }
          break
      }
    }

    const livePreviewIframe = ref()

    const setupCommunicationWithInspector = () => {
      window.addEventListener('message', event => {
        processInspectorMessage(event)
      })
    }

    const postToInspector = (event, value) => {
      livePreviewIframe.value.contentWindow.postMessage({ event, value }, '*')
    }

    setupCommunicationWithInspector()
    onMounted(() => {
      store.commit('themeEditor/UPDATE_POST_INSPECTOR_CALLBACK', postToInspector)
    })

    return {
      onSubmitSection,
      onSectionUpdated,
      onBlockSorting,
      onBlockDeleted,
      livePreviewIframe,
    }
  },
}
</script>

<style lang="scss">
#livePreview {
  height: 100%;
  margin: 0 auto;
  //border: unset;
}
</style>
